
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        

































































































.green {
  color: $c-green;
}
.export {
  position: absolute;
  bottom: 160px;
  left: 71%;
  @media screen and (max-width: 767px) {
    position: relative;
    bottom: unset;
    left: unset;
    width: 100%;
    display: block;
    gap: unset;
    align-items: stretch;
    margin-top: 3rem;

    a {
      width: 100%;
    }
  }
}
.consommation-ep {
  &__total {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    align-items: center;
    text-align: center;
    margin: 55px 0 50px;

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px 0;
      text-align: left;
      margin-top: unset;
      margin-bottom: 30px;
    }

    p {
      display: flex;
      flex-direction: column;
      font-weight: 500;
      font-size: 1.4rem;
      color: #8b8b8b;
      margin-top: 0;
    }
  }

  &__graph {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__graph-responsive {
    display: none;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}
.facets {
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  margin-bottom: 4rem;

  @include mq(m) {
    flex-flow: row wrap;
  }

  &__search {
    width: 100%;
    margin-bottom: 2rem;
    @include mq(m) {
      max-width: 41.9rem;
      margin-bottom: unset;
    }

    .input-wrapper {
      position: relative;

      &:after {
        content: url('../../../assets/svg/commune/search.svg');
        width: 1.9rem;
        height: 2rem;
        position: absolute;
        right: 2rem;
        top: calc(50% - 1rem);
        transition: opacity 0.3s ease-in-out;
      }

      &:focus-within:after {
        opacity: 0.5;
      }
    }

    input.search {
      width: 100%;
      border: 0;
      background-color: $c-gray-lightest;
      border-radius: 0.5rem;
      height: 5.5rem;
      padding: 1rem 2rem;

      &:focus {
        border: 1px solid $c-gray-darker;
        background-color: $c-white;
      }
    }
  }

  &__filters {
    font-size: 1.4rem;
    display: flex;
    flex-flow: column;
    margin-left: auto;
    width: 100%;

    @include mq(m) {
      display: flex;
      flex-flow: row wrap;
      width: auto;
    }

    &__label {
      display: flex;
      align-items: center;
      color: $c-main-primary;
      margin-right: 1rem;
      margin-bottom: 1rem;

      @include mq(m) {
        margin-bottom: unset;
      }

      > svg {
        margin-right: 0.6rem;
        fill: $c-main-primary;
      }
    }
  }
}
