
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        


















































.breadcrumb {
  font-size: 1.4rem;
  padding-right: 50%;
  margin-block: 0;
  padding-block: 50px;

  @media screen and (max-width: 767px) {
    padding-right: 0;
    padding-bottom: 0;
  }
}

.page-header-image {
  max-width: 70px;
  @media screen and (max-width: 767px) {
    margin-top: 4rem;
  }
}

.page-header {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  @include mq(m) {
    flex-direction: row;
    align-items: flex-start;
  }

  &__title {
    h1.main-title {
      display: block;

      @include mq(m) {
        display: inline-block;
      }
    }

    span.subtitle {
      display: inline-block;
      margin-left: 0;
      font-size: 1.4rem;
      @include mq(m) {
        margin-left: 1.5rem;
      }
    }

    .main-subtitle {
      display: inline-block;
      margin-left: 1rem;

      @media screen and (max-width: 960px) {
        margin-left: unset;
      }
    }
  }

  &__actions {
    margin-left: auto;
    flex: 0 0 auto;
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media screen and (max-width: 767px) {
      width: 100%;
      align-items: stretch;
      margin-top: 30px;

      a {
        width: 100%;
      }
    }
  }
}
